import type { ReactNode } from "react";
import { isValidElement, useLayoutEffect, useRef, useState } from "react";
import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";

import { Slot } from "@radix-ui/react-slot";

import { cn } from "~/lib/ui";

import { Button } from "./button";

const variants = tv({
  variants: {
    lines: {
      "1": "line-clamp-1",
      "2": "line-clamp-2",
      "3": "line-clamp-3",
      "4": "line-clamp-4",
      "5": "line-clamp-5",
      "6": "line-clamp-6",
      // dynamic line clamp variants , notice [n] is a placeholder
      "7": "line-clamp-[7]",
      "8": "line-clamp-[8]",
      "9": "line-clamp-[9]",
      "10": "line-clamp-[10]",
      "11": "line-clamp-[11]",
      "12": "line-clamp-[12]",
      "13": "line-clamp-[13]",
      "14": "line-clamp-[14]",
      "15": "line-clamp-[15]",
      "16": "line-clamp-[16]",
      "17": "line-clamp-[17]",
      "18": "line-clamp-[18]",
      "19": "line-clamp-[19]",
      "20": "line-clamp-[20]",
      // more line clamp variants if needed
    },
    defaultVariants: { lines: "6" },
  },
});
type Variants = Omit<VariantProps<typeof variants>, "defaultVariants">;

type Props = Variants & { className?: string } & (
    | {
        children: ReactNode;
        /**
         * Default button className , cannot be used with button
         */
        buttonClassName?: string;
        button?: never;
        flexDirection?: "flex-col" | "flex-row";
      }
    | {
        children: ReactNode;
        /**
         * Default button className , cannot be used with button
         */
        buttonClassName?: never;
        /**
         * Custom button component
         * if ReactNode it will have isExpanded as a prop
         * @uses Slot
         */
        button?: ((props: { isExpanded: boolean }) => ReactNode) | ReactNode;
        flexDirection?: "flex-col" | "flex-row";
      }
  );
const DefaultButton = ({
  isExpanded,
  className,
}: {
  isExpanded: boolean;
  className?: string;
}) => (
  <Button
    className={cn(
      "mt-4 text-xs font-bold text-brand-primary-black underline-offset-1",
      className,
    )}
    variant="linkInlineUnderlined"
  >
    {isExpanded ? "See Less" : "See More"}
  </Button>
);
/**
 * ExpandableText
 * Usage:
 * ```tsx
 * <ExpandableText lines="5" className="h-10" button={Button}>
 *  <p className="text-lg">
 *    The beauty of react w/ tailwind variants is that
 *    it allows you to be extremely flexible without
 *    technical limitations. This is a great example
 *    of how you can use it to create a see more/less
 *    text component that will truncate the text and
 *    show a see more button if the text is too long.
 *    The text will be truncated to 6 lines by
 *    default, but you can change that by passing the
 *    lines prop to the component. The component will
 *    also add an ellipsis to the end of the text if
 *    it is truncated. The see more button will toggle
 *    the text between the truncated and full text.
 *  </p>
 *  <p>
 *    Like here we can add multiple children and we
 *    can set any text with any components
 *    (p,div,span,links,ul ) and it will still work as
 *    expected.
 *  </p>
 * </ExpandableText>
 * ```
 */
export const ExpandableText = ({
  children,
  lines, // Default to 6 lines if not provided
  button = DefaultButton, // Button passed as a function component
  buttonClassName,
  className,
  flexDirection = "flex-col",
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isEllipsis, setIsEllipsis] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const isTextOverflowing = ref.current?.scrollHeight
      ? ref.current?.scrollHeight > ref.current?.clientHeight + 1 // Added a small margin to account for rounding issues
      : false;
    setIsEllipsis(isTextOverflowing);
  }, []);

  const toggleExpansion = () => {
    setIsExpanded(prev => !prev);
  };

  return (
    <div className={cn("flex items-start", flexDirection)}>
      <div
        ref={ref}
        className={cn(
          "h-auto w-full overflow-hidden text-ellipsis",
          variants({ lines: lines }),
          className,
          isExpanded ? "!line-clamp-none" : "",
        )}
      >
        {children}
      </div>
      {isEllipsis && button && (
        <Slot onClick={toggleExpansion}>
          {isValidElement(button) ? (
            button
          ) : typeof button === "function" ? (
            button({ isExpanded })
          ) : (
            <DefaultButton
              isExpanded={isExpanded}
              className={buttonClassName}
            />
          )}
        </Slot>
      )}
    </div>
  );
};
